import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // home
  {
    // 首頁
    path: '/',
    name: 'home',
    meta: {
      title: '免費體驗AI評估',
    },
    component: () => import('@/components/home'),
  },

  //login
  {
    // 登錄
    path: '/login',
    name: 'login',
    meta: {
      needLogin: false, //設置此路由是否需要登錄狀態
      title: '登錄',
    },
    component: () => import('@/components/login'),
  },

  //user
  {
    //個人中心
    path: '/user',
    name: 'user',
    meta: {
      needLogin: false, //設置此路由是否需要登錄狀態
      title: '我的',
    },
    component: () => import('@/components/user'),
  },

  {
    //歷史記錄
    path: '/user/history',
    name: 'userHistory',
    meta: {
      needLogin: false, //設置此路由是否需要登錄狀態
      title: '歷史記錄',
    },
    component: () => import('@/components/user/history'),
  },

  {
    path: '/jkzc/question',
    name: 'jkzcQuestion',
    meta: {
      needLogin: false, //設置此路由是否需要登錄狀態
      title: '問卷調查',
    },
    component: () => import('../components/question/questionJkzc'),
  },
  {
    path: '/jkzc/result/:id',
    name: 'jkzcResult',
    meta: {
      needLogin: false, //設置此路由是否需要登錄狀態
      title: '評估結果',
    },
    component: () => import('../components/result/jkzc'),
  },

  {
    path: '/bj/question',
    name: 'bjQuestion',
    meta: {
      needLogin: false, //設置此路由是否需要登錄狀態
      title: '問卷調查',
    },
    component: () => import('../components/question/questionBj'),
  },
  {
    path: '/bj/result/:id',
    name: 'bjResult',
    meta: {
      needLogin: false, //設置此路由是否需要登錄狀態
      title: '評估結果',
    },
    component: () => import('../components/result/baijinReport'),
  },
  {
    path: '/four/question',
    name: 'fourQuestion',
    meta: {
      needLogin: false, //設置此路由是否需要登錄狀態
      title: '問卷調查',
    },
    component: () => import('../components/question/questionFour.vue'),
  },
  {
    path: '/four/result/:id',
    name: 'fourResult',
    meta: {
      needLogin: false, //設置此路由是否需要登錄狀態
      title: '評估結果',
    },
    component: () => import('@/components/result/four.vue'),
  },
  {
    path: '/myLink',
    name: 'myLink',
    meta: {
      needLogin: false, //設置此路由是否需要登錄狀態
      title: '米因AI健康自測',
    },
    component: () => import('@/components/myLink/index.vue'),
  },
  {
    path: '/myLink/result/:id',
    name: 'myLinkResult',
    meta: {
      needLogin: false, //設置此路由是否需要登錄狀態
      title: '米因AI健康自測',
    },
    component: () => import('@/components/myLink/result.vue'),
  },
  {
    path: '/myLink/report/A03/:rate',
    name: 'myLinkReportXJGS',
    meta: {
      needLogin: false, //設置此路由是否需要登錄狀態
      title: '心肌梗塞',
    },
    component: () => import('@/components/myLink/report/xjgs.vue'),
  },
  {
    path: '/myLink/report/A07/:rate',
    name: 'myLinkReportCancer',
    meta: {
      needLogin: false, //設置此路由是否需要登錄狀態
      title: '癌症(恶性肿瘤)',
    },
    component: () => import('@/components/myLink/report/cancer.vue'),
  },
  {
    path: '/myLink/report/A17/:rate',
    name: 'myLinkReportNZZ',
    meta: {
      needLogin: false, //設置此路由是否需要登錄狀態
      title: '脑卒中',
    },
    component: () => import('@/components/myLink/report/nzz.vue'),
  },
  {
    path: '/myLink/report/A05/:rate',
    name: 'myLinkReportTF',
    meta: {
      needLogin: false, //設置此路由是否需要登錄狀態
      title: '痛風',
    },
    component: () => import('@/components/myLink/report/tf.vue'),
  },
  {
    path: '/myLink/pay/:id',
    name: 'myLinkPay',
    meta: {
      needLogin: false, //設置此路由是否需要登錄狀態
      title: '支付',
    },
    component: () => import('@/components/myLink/pay.vue'),
  },
  {
    path: '/myLink/question/four',
    name: 'myLinkQuestionFour',
    meta: {
      needLogin: false, //設置此路由是否需要登錄狀態
      title: '问卷',
    },
    component: () => import('@/components/myLink/question/four.vue'),
  },
  {
    path: '/myLink/history',
    name: 'myLinkHistory',
    meta: {
      needLogin: false, //設置此路由是否需要登錄狀態
      title: '问卷',
    },
    component: () => import('@/components/myLink/history.vue'),
  },

  //overtime
  {
    path: '/overtime',
    name: 'overtime',
    meta: {
      title: '超時',
    },
    component: () => import('@/components/overtime'),
  },
  //loading
  {
    path: '/loading/:type/:id',
    name: 'loading',
    meta: {
      title: '加载中...',
    },
    component: () => import('@/components/loading'),
  },

  //404
  {
    path: '/404',
    name: '404',
    meta: {
      title: '404',
    },
    component: () => import('@/components/404'),
  },
  {
    path: '*',
    redirect: '/404',
  },
]

const router = new VueRouter({
  routes: routes,
})

export default router
